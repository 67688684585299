import React from "react"
import tw, { styled } from "twin.macro"

const SectionHeader = ({
  tag,
  index,
  text,
  emphasizedText,
  emphasizedTag = "span",
  size = "lg",
  width,
  font,
}) => {
  return (
    <Wrapper size={size} width={width} font={font}>
      <Tag size={size}>
        {tag}
        {index ? (
          <span>
            <br />
            (No. {index})
          </span>
        ) : (
          ""
        )}
      </Tag>
      <EmphasizedText as={emphasizedTag}>{text}<br/>{emphasizedText}</EmphasizedText>
    </Wrapper>
  )
}

export default SectionHeader

const Wrapper = styled.div`
  ${tw`flex flex-col self-start relative z-10`}
  border-top: 2px solid #E7E7E7;
  font-family: "Roboto";
  font-weight: 500;
  user-select: none;
  white-space: wrap;
  white-space: pre-wrap;
  color: #e7e7e7;
  ${({ size }) => {
    if (size === "lg") {
      return `line-height: 112%;
      font-size: 4.8vw;
      margin-bottom: 4.5vw;`
    } else if (size === "md") {
      return `line-height: 120%;
      font-size: 4.2vw;
      margin-bottom: 4vw;`
    } else if (size === "sm") {
      return `line-height: 112%;
        font-size: 3.5vw;
        margin-bottom: 4vw;`
    } else {
      return null
    }
  }}
  ${({ width }) => width && `min-width:${width}`}

  @media(max-width:1024px) {
    border-top: 1px solid #e7e7e7;
    ${tw`w-full`}
    font-size: ${({ font }) => (font ? font : "7vw")};
  }
  @media (max-width: 760px) {
    border-top: 1px solid #e7e7e7;
    ${tw`w-full`}
    font-size: ${({ font }) => (font ? font : "10.5vw")};
  }
`

const Tag = styled.span`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.75vw;
  color: #e7e7e7;
  line-height: 130%;
  ${({ size }) => {
    if (size === "lg") {
      return `margin: 0.4vw 0vw 1.5vw 0vw;`
    } else if (size === "md") {
      return `margin: 0.4vw 0vw 1vw 0vw;`
    } else if (size === "sm") {
      return `margin: 0.4vw 0vw 1.1vw 0vw;`
    } else {
      return null
    }
  }}
  @media(max-width: 1024px) {
    font-size: 2vw;
    margin: 1.5vw 0vw 2vw 0vw;
  }
  @media (max-width: 760px) {
    font-size: 3.2vw;
    margin: 2vw 0vw 5vw 0vw;
  }
`

const EmphasizedText = styled.span`
  font-family: "Roboto";
  font-weight: "400";
  font-size: inherit;
`
