import React, { useState } from "react"
import { globalHistory as history } from "@reach/router"
import { GlobalStyles } from "twin.macro"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import SEO from "./seo"
import Modal from "../components/Modal"

const Layout = ({ children }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalVariant, setModalVariant] = useState(false)

  const handleShowTosModal = () => {
    setModalVariant("tos")
    setShowModal(true)
  }
  const handleShowPolicyModal = () => {
    setModalVariant("policy")
    setShowModal(true)
  }

  const hideModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <GlobalStyles />
      {/*
      <Helmet>
        <link rel="icon" href={favicon} />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Roboto:wght@100;300;400;500;700;900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/dcu3ozy.css" />
        <title>{"Nemezink"}</title>
      </Helmet>*/}
      <SEO />
      <Header location={history.location.pathname} />
      <main>{children}</main>
      <Footer
        location={history.location.pathname}
        handleTosModal={handleShowTosModal}
        handlePolicyModal={handleShowPolicyModal}
      />
      <Modal
        variant={modalVariant}
        isShown={showModal}
        handleModal={hideModal}
      />
    </>
  )
}

export default Layout
