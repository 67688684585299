import React, { useEffect, useState, useRef, useContext } from "react"
import logo from "../images/logo_nemezink_white.svg"
import { FaInstagram, FaFacebookSquare, FaBars, FaPinterestSquare } from "react-icons/fa"
import tw, { styled } from "twin.macro"
import { useViewportScroll, motion } from "framer-motion"
import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';

const MobileHeader = () => {
  const context = useContext(I18nextContext);
  const { changeLanguage } = useI18next();

  const { scrollY } = useViewportScroll()
  const [headerVariant, setHeaderVariant] = useState("secondary")
  const [menuOpen, setMenuOpen] = useState(false)
  const headerVariants = {
    primary: {
      transform: "translateY(0%)",
    },
    secondary: {
      transform: "translateY(-100%)",
    },
  }
  const previousY = useRef(0)
  useEffect(() => {
    function updateHeader() {
      if (scrollY.current <= 0) {
        setHeaderVariant("primary")
      } else if (scrollY.current > previousY.current) {
        setHeaderVariant("secondary")
        setMenuOpen(false)
      } else if (scrollY.current < previousY.current) {
        setHeaderVariant("primary")
      }
      previousY.current = scrollY.current
    }
    const unsubY = scrollY.onChange(updateHeader)
    updateHeader()
    return () => unsubY()
  }, [scrollY])

  return (
    <Wrapper
      variants={headerVariants}
      initial="primary"
      animate={headerVariant}
      transition={{ type: "spring", bounce: 0, duration: 0.5 }}
    >
      <div tw="flex flex-row w-full justify-between items-center">
        <Link to="/">
          <Logo src={logo} alt="studio tatuażu Kraków Nemezink - Logo " />
        </Link>
        <Icons>
          <a
            href="https://www.facebook.com/Nemezink-Tattoo-Studio-102564294946277"
            alt="nemezink facebook"
          >
            <FbIcon />
          </a>
          <a
            href="https://www.instagram.com/nemezink/"
            alt="nemezink instagram"
          >
            <IgIcon />
          </a>
          <a
            href="https://www.pinterest.com/nemezink"
            alt="nemezink pinterest"
          >
            <PinterestIcon />
          </a>
          <MenuIcon onClick={() => setMenuOpen(!menuOpen)} />
        </Icons>
      </div>
      <Menu
        isOpen={menuOpen}
        initial="collapsed"
        animate={menuOpen ? "open" : "collapsed"}
        exit="collapsed"
        transition={{
          duration: 0.5,
          /*ease: [0.04, 0.62, 0.23, 0.98]*/ ease: "easeOut",
        }}
        variants={{
          open: { opacity: 1, height: "auto" },
          collapsed: { opacity: 1, height: 0 },
        }}
      >
        <MenuItems>
          <MenuItem
            to="/"
            currentPath={
              typeof window !== "undefined" &&
              (window.location.pathname === "/" || window.location.pathname === "/en/")
            }
          >
            START
          </MenuItem>
          <MenuItem
            currentPath={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/studio") ||
                window.location.pathname.startsWith("/en/studio"))
            }
            to="/studio"
          >
            STUDIO
          </MenuItem>
          <MenuItem
            currentPath={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/tattoo") ||
                window.location.pathname.startsWith("/en/tattoo"))
            }
            to="/tattoo"
          >
           TATTOO
          </MenuItem>
          <MenuItem
            currentPath={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/faq") ||
                window.location.pathname.startsWith("/en/faq"))
            }
            to="/faq"
          >
            FAQ
          </MenuItem>
          <MenuItem
            to="https://blog.nemezink.pl/"
          >
            BLOG
          </MenuItem>
          <MenuItem
            currentPath={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/contact") ||
              window.location.pathname.startsWith("/en/contact"))
            }
            to="/contact"
          >
            {context.language === 'pl' ? 'KONTAKT' : 'CONTACT'}
          </MenuItem>
          <MenuItem
            style={{ marginTop: '15px' }}
            to="tel:+48690971677"
          >
            +48 690 971 677
          </MenuItem>
        </MenuItems>
        <div tw="flex flex-row items-center justify-center h-full cursor-pointer">
          <LangButton
            active={context.language === "pl"}
            onClick={(e) => {
              e.preventDefault();
              changeLanguage('pl');
            }}
          >PL</LangButton>
          <div
            style={{
              width: "2px",
              backgroundColor: "#fbfbfb",
              height: "5vw",
              margin: "0vw 3vw"
            }}
          />
          <LangButton
            active={context.language === "en"}
            onClick={(e) => {
              e.preventDefault();
              changeLanguage('en');
            }}
          >EN</LangButton>
        </div>
      </Menu>
    </Wrapper >
  )
}

const LangButton = styled.a`
    font-weight: ${({ active }) => (active ? "bold" : "normal")};
    font-size: ${({ active }) => (active ? "4vw" : "3.5vw")};
    color: ${({ active }) => (active ? "#fbfbfb" : "#e7e7e7")};
    @media (max-width: 760px) {
    font-size: ${({ active }) => (active ? "7vw" : "6vw")};
    }
`

const Wrapper = styled(motion.div)`
  ${tw`flex-col w-full items-center`}
  display:none;
  padding: 2vw 5vw 2vw 5vw;
  background-color: #000000;
  @media (max-width: 1024px) {
    display: flex;
  }
  @media (max-width: 760px) {
    padding: 2vw 3vw 2vw 3vw;
    display: flex;
  }
`

const Menu = styled(motion.div)`
  overflow: hidden;
`
const MenuItems = styled.div`
  ${tw`flex-col items-center flex`}
  flex-wrap: nowrap;
  text-align: center;
  width: 100%;
  padding-top: 3vw;
  gap: 10px;
`
const MenuItem = styled(Link)`
  ${tw`uppercase`}
  text-align: center;
  font-size: 4vw;
  font-weight: bold;
  font-family: "Space Mono";
  color: #e7e7e7;
  width: 100%;
  text-decoration: ${({ currentPath }) => (currentPath ? "underline" : "")};
  @media (max-width: 760px) {
    font-size: 7vw;
  }
`

const Logo = styled.img`
  height: 13vw;
  @media (max-width: 760px) {
    height: 18vw;
  }
`

const Icons = styled.div`
  ${tw`flex flex-row items-center`}
`

const MenuIcon = styled(FaBars)`
  ${tw`cursor-pointer`}
  color:#e7e7e7;
  font-size: 10vw;
  margin: 0vw 1vw;
  @media (min-width: 760px) {
    font-size: 8vw;
    margin: 0vw 1.5vw;
  }
`
const FbIcon = styled(FaFacebookSquare)`
  ${tw`cursor-pointer`}
  color:#e7e7e7;
  font-size: 10vw;
  margin: 0vw 1vw;
  @media (min-width: 760px) {
    font-size: 8vw;
    margin: 0vw 1.5vw;
  }
`
const IgIcon = styled(FaInstagram)`
  ${tw`cursor-pointer`}
  color:#e7e7e7;
  font-size: 10vw;
  margin: 0vw 1vw;
  @media (min-width: 760px) {
    font-size: 8vw;
    margin: 0vw 1.5vw;
  }
`

const PinterestIcon = styled(FaPinterestSquare)`
  ${tw`cursor-pointer`}
  color:#e7e7e7;
  font-size: 10vw;
  margin: 0vw 1vw;
  @media (min-width: 760px) {
    font-size: 8vw;
    margin: 0vw 1.5vw;
  }
`

export default MobileHeader
